<template>
  <div>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <per-button type="primary" @click="dialog.typeAddState = true" style="margin-bottom: 10px" per="style_image_type:add">添加类型</per-button>
      <el-table
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.TypeList"
          border
          max-height="650"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column label="类型名称" align="center" #default="scope">
          {{scope.row.imageType}}
          <el-badge type="primary" value="系统默认" v-if="scope.row.isDefault === 1"/>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content">
                可筛可选&ensp;&ensp;&ensp;&ensp; : 可用作筛选,可用作设定<br/><br/>
                可筛不可选&ensp;&ensp; : 筛选可用,设定不可用<br/><br/>
                不可筛不可选 : 不可筛选,不可设定</div>
              <span>状态&nbsp;<i class="el-icon-question"/></span>
            </el-tooltip>

          </template>
          <template slot-scope="scope">
              <el-dropdown @command="changeState" split-button :type="scope.row.state===0?'success':(scope.row.state===1?'warning':'danger')" size="mini">
                {{scope.row.state===0?'可筛可选&ensp;&ensp;&ensp;&ensp;':(scope.row.state===1?'可筛不可选&ensp;&ensp;':'不可筛不可选')}}
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="[0,scope.row]">可筛可选</el-dropdown-item>
                  <el-dropdown-item :command="[1,scope.row]">可筛不可选</el-dropdown-item>
                  <el-dropdown-item :command="[2,scope.row]">不可筛不可选</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column label="是否系统默认参数" v-if="isSuperAdmin" align="center" #default="scope">
          <el-switch
              @change="isChange(scope.row)"
              v-model="scope.row.isDefault"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-table-column>
        <el-table-column label="婚纱详情(Pad)展示" align="center" #default="scope">
          <el-switch
              @change="isClothesPadChange(scope.row)"
              v-model="scope.row.isClothesPad"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group >
              <per-button v-if="scope.row.isDefault!==1" type="warning" size="mini" @click="openEdit(scope.row)" per="style_image_type:edit">编辑</per-button>
              <per-button v-if="scope.row.isDefault!==1" type="danger" size="mini" @click="delType(scope.row)" per="style_image_type:del">删除</per-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <styleImageTypeAdd v-if="dialog.typeAddState"  :state="dialog.typeAddState" @close="close"/>
    <styleImageTypeEdit v-if="dialog.typeEditState" :type="dialog.type" :state="dialog.typeEditState" @close="close"/>
  </div>
</template>

<script>
import styleImageTypeAdd from "@/pages/parameter/style-image-type/style-image-type-add";
import styleImageTypeEdit from "@/pages/parameter/style-image-type/style-image-type-edit";

export default {
  name: "style-image-type-list",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        TypeList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        typeAddState: false,
        typeEditState: false,
        type: {},
      },
      isSuperAdmin: localStorage.getItem("tenantCrop") === '2a31c23a-c178-441614928053489'
    }
  },
  components: {styleImageTypeAdd,styleImageTypeEdit,},
  created() {
    this.queryTypeList();
  },
  methods: {
    //查询客户列表
    queryTypeList: function () {
      this.$axios({
        method: "GET",
        url: "/styleImageType/queryList",
        params: {
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.table.total=response.data.data.total;
        this.table.TypeList = response.data.data.list;
        console.log(this.table.TypeList)
      })
    },
    //删除类型
    delType(data){
      let value = "是否删除该款式图片类型?"
      this.$confirm(value, '删除图片类型:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/styleImageType/delType",
          params: {
            id:data.id
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self = this;
            setTimeout(function () {
              self.queryTypeList();
            }, 1000)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //更改State
    changeState(comm){
      let text=comm[0]===0?'可筛可选':(comm[0]===1?'可筛不可选':'不可筛不可选')
      this.$confirm("是否更将 < "+comm[1].imageType+" > 状态改为 < "+text+" >?",
          '删除图片类型:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/styleImageType/changeState",
          params: {
            id:comm[1].id,
            state:comm[0]
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
            let self = this;
            setTimeout(function () {
              self.queryTypeList();
            }, 1000)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //打开编辑店铺
    openEdit(data) {
      this.dialog.typeEditState = true
      this.dialog.type = data
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryTypeList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryTypeList()
    },
    close: function (state) {
      this.dialog.typeAddState = state;
      this.dialog.typeEditState = state;
      this.queryTypeList();
    },
    isChange(value) {
      this.$axios({
        method: 'post',
        url: "/styleImageType/changeState",
        params: {
          id: value.id,
          isDefault: value.isDefault
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryTypeList()
      })
    },
    isClothesPadChange(value) {
      this.$axios({
        method: 'post',
        url: "/styleImageType/changeState",
        params: {
          id: value.id,
          isClothesPad: value.isClothesPad
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" : response.data.msg,
          type: flag ? 'success' : 'error',
          duration: 1000,
        })
        this.queryTypeList()
      })
    },
  }
}
</script>

<style scoped>

</style>